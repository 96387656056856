<template>
  <m-error code="500" message="抱歉，服务器出错了" :error-img="image500" :link="link"></m-error>
</template>

<script>
import errorTemplate from "@/components/error/Index";
import image500 from "@/assets/images/error/500.svg";
export default {
  data() {
    return {
      image500,
      link: {
        text: "返回首页",
        url: "/"
      }
    };
  },
  components: {
    "m-error": errorTemplate
  }
};
</script>
